const passwordMin = 8
const passwordMax = 64

export const getValidationMessage = function (key, options = null) {
  return {key, options}
}

export const minMaxValidationRule = function(fieldName, minLength, maxLength) {
  return {
    validator: function(v) {
      return v.length >= minLength && v.length <= maxLength
    },
    message: getValidationMessage('validation.minMaxValidation', {fieldName, minLength, maxLength})
  }
}

export const minLengthValidationRule = function(fieldName, minLength) {
  return {
    validator: function(v) {
      return v.length >= minLength
    },
    message: getValidationMessage('validation.minLengthValidation', {fieldName, minLength})
  }
}

export const requiredValidationRule = function(fieldName) {
  return {
    validator: function(v) {
      return v && ((Array.isArray(v) && v.length > 0) || (!Array.isArray(v) && (!v.trim || v.trim() !== '')))
    },
    message: getValidationMessage('validation.requiredValidation', {fieldName})
  }
}

export const containsValidationRule = function(content, fieldName) {
  return {
    validator: function(v) {
      return content().includes(v)
    },
    message: getValidationMessage('validation.containsValidation', {fieldName})
  }
}

export const requiredCheckboxValidationRule = function(fieldName) {
  return {
    validator: function(v) {
      return v === true
    },
    message: getValidationMessage('validation.requiredValidation', {fieldName})
  }
}

export const emailValidationRule = function(fieldName) {
  return {
    validator: function(v) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(v)
    },
    message: getValidationMessage('validation.formatValidation', {fieldName})
  }
}

export const passwordValidationRule = function(fieldName) {
  return {
    validator: function(v) {
      return v.length >= passwordMin && v.length <= passwordMax
    },
    message: getValidationMessage('validation.minMaxValidation', {fieldName, minLength: passwordMin, maxLength: passwordMax})
  }
}

export const minMaxNumberValidationRule = function(fieldName, minNumber, maxNumber, allowEmpty) {
  const locale = useNuxtApp().$i18n?.locale?.value || 'en'
  const minNumberFormattedValue =  minNumber?.toLocaleString(locale === 'en' ? 'en' : 'de')
  const maxNumberFormattedValue =  maxNumber?.toLocaleString(locale === 'en' ? 'en' : 'de')
  return {
    validator: function(v) {
      let value = parseFloat(v)
      if (isNaN(value)) {
        return allowEmpty && v === null || v?.trim() === ''
      }
      return value >= minNumber && value <= maxNumber
    },
    message: minNumber !== maxNumber ? getValidationMessage('validation.minMaxNumberValidation', {fieldName, minNumber: minNumberFormattedValue, maxNumber: maxNumberFormattedValue}) : getValidationMessage('validation.numberExactly', {fieldName, minNumber})
  }
}

export const minNumberValidationRule = function(fieldName, minNumber, allowEmpty, fallbackValue) {
  return {
    validator: function(v) {
      let value = parseFloat(v)
      if (isNaN(value)) {
        return allowEmpty && v === null || v?.trim() === ''
      }
      return value >= minNumber
    },
    message: getValidationMessage('validation.minNumberValidation', {fieldName, minNumber}),
    fallbackValue: fallbackValue
  }
}

export const maxNumberValidationRule = function(fieldName, maxNumber, allowEmpty) {
  return {
    validator: function(v) {
      let value = parseFloat(v)
      if (isNaN(value)) {
        return allowEmpty && v === null || v?.trim() === ''
      }
      return value <= maxNumber
    },
    message: getValidationMessage('validation.maxNumberValidation', {fieldName, maxNumber})
  }
}

export const requiredNumberValidationRule = function (fieldName) {
  return {
    validator: function (v) {
      return (typeof v === 'number' && v === v)
    },
    message: getValidationMessage('validation.requiredValidation', {fieldName})
  }
}

export const greaterThanValidationRule = function(fieldName1, fieldName2, minNumber, allowEmpty, fallbackValue) {
  return {
    validator: function(v) {
      let value = parseFloat(v)
      if (isNaN(value)) {
        return allowEmpty && v === null || v?.trim() === ''
      }
      // eslint-disable-next-line eqeqeq
      return minNumber != null ? value > minNumber : true
    },
    message: getValidationMessage('validation.greaterThanValidation', {fieldName1, fieldName2}),
    fallbackValue: fallbackValue
  }
}

export const lessThanValidationRule = function(fieldName1, fieldName2, maxNumber, allowEmpty) {
  return {
    validator: function(v) {
      let value = parseFloat(v)
      if (isNaN(value)) {
        return allowEmpty && v === null || v?.trim() === ''
      }
      // eslint-disable-next-line eqeqeq
      return maxNumber != null ? value < maxNumber : true
    },
    message: getValidationMessage('validation.lessThanValidation', {fieldName1, fieldName2})
  }
}
